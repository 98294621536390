<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-topup" centered hide-footer size="lg" :no-close-on-backdrop="true" content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="uil uil-top-arrow-from-top text-primary"></i> Top Up
        </h5>
      </template>
      <div class="row align-items-center">
        <div class="col-12">
          <p class="font-size-14 text-muted mb-3">Add funds to the merchant's wallet.</p> 
        </div>
        <div class="col-12">
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Merchant</label>
            <div class="col-lg-9 col-12">
              <multiselect v-model="selectedmerchant" :options="merchant" label="account_name" class="" placeholder="Select Merchant" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="disabled" @input="changeMerchant()" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.merchantId.$error,
                  }">
              <template slot="singleLabel" slot-scope="props">
                <span v-if="props.option.account_db_code!=='all'">
                  <span class="option__title">
                  <span v-if="props.option.status=='inactive'" class="text-danger me-1 text-uppercase">&#9679;</span>
                  <span v-else-if="props.option.status=='active'" class="text-success me-1 text-uppercase">&#9679; </span>
                  #{{ props.option.id }} {{ props.option.account_name }}</span>
                </span>
                <span v-else>
                  {{ props.option.account_name }} <span v-if="merchant.length>1">({{ merchant.length-1 }})</span><span v-else>(0)</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <span v-if="props.option.account_db_code!=='all'">
                  <span class="option__title">
                  <span v-if="props.option.status=='inactive'" class="text-danger me-1 text-uppercase">&#9679;</span>
                  <span v-else-if="props.option.status=='active'" class="text-success me-1 text-uppercase">&#9679; </span>
                  #{{ props.option.id }} {{ props.option.account_name }}</span>
                </span>
                <span v-else>
                  {{ props.option.account_name }} <span v-if="merchant.length>1">({{ merchant.length-1 }})</span><span v-else>(0)</span>
                </span>
              </template>
              <span slot="noResult">Oops! No merchant found. </span>
            </multiselect>
              <div v-if="modalSubmit && $v.formData.merchantId.$error" class="invalid-feedback">
                <span v-if="!$v.formData.merchantId.required">Merchant is required.</span>
                <span v-else-if="!$v.formData.merchantId.equalDefault">Merchant Id is required.</span>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Available Balance</label>
            <div class="col-lg-9 col-12">
              <multiselect
                  ref="select"
                  v-model="selectedmerchantContract"
                  :options="merchantContract"
                  label="account_name"
                  class="mb-3"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="changeContract()"
                  :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.contractId.$error,
                  }"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id!=='-1'">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.contract_id }}: {{ props.option.contract_name }} - {{ props.option.contract_currency }} 
                      <span v-if="props.option.contract_balance">
                        {{ convertCurrencyFormat(props.option.contract_balance, true)}}
                      </span>
                      <span v-else> 0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id!=='-1'">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title">  {{ props.option.contract_id }}: {{ props.option.contract_name }} - {{ props.option.contract_currency }} 
                      <span v-if="props.option.contract_balance">
                        {{ convertCurrencyFormat(props.option.contract_balance, true)}}
                      </span>
                      <span v-else> 0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
              </multiselect>
              <div v-if="modalSubmit && $v.formData.contractId.$error" class="invalid-feedback">
                <span v-if="!$v.formData.contractId.required">Contract is required.</span>
                <span v-else-if="!$v.formData.contractId.equalDefault">Contract Id is required.</span>
              </div>
              <div class="mt-2" v-if="selectedmerchantContract.contract_id !==-1">
                <h5 class="font-size-14 bg-soft-primary text-primary p-2 fw-medium mb-0 d-inline-block rounded">
                  <i class="uil uil-wallet"></i>
                  {{selectedmerchantContract.contract_currency}}
                  <span v-if="selectedmerchantContract.contract_balance">
                    {{ convertCurrencyFormat(selectedmerchantContract.contract_balance, true)}}
                  </span>
                  <span v-else> 0.00</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Top Up Amount <span class="text-muted d-block fw-normal font-size-13">(Min. {{selectedmerchantContract.contract_currency}} <span v-if="currencyMin">{{ convertCurrencyFormat(currencyMin,false) }}</span>)</span></label>
            <div class="col-lg-9 col-12">
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1">{{selectedmerchantContract.contract_currency}}</span>
                <input :disabled="loading" v-model.trim="formData.topupAmount" type="tel" @input="inputNumberOnly($event, 'formData','topupAmount'), $v.formData.$touch();" @blur="changeCurrency()" class="form-control" placeholder="Top Up Amount" name="topupAmount" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.topupAmount.$error
                  }" />
                <div v-if="modalSubmit && $v.formData.topupAmount.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.topupAmount.required">Top Up is required.</span>
                  <span v-if="!$v.formData.topupAmount.minTopup"> Amount should be greater than the {{selectedmerchantContract.contract_currency}} {{currencyMin}}. </span>
                </div>
                <!-- <div v-if="modalSubmit && $v.formData.topupAmountAfterCovertRate.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.topupAmountAfterCovertRate.minSettlement">Must be greater than the minimum settlement amount.</span>
                </div> -->
              </div>
            </div>
          </div>

          <div class="mb-3 row">

        
            <label class="col-lg-3 col-12 col-form-label">Merchant Receives Amount</label>
            <div class="col-lg-9 col-12">
              <div class="bg-light p-3 px-3 rounded">
                <table class="table mb-0">
                <tr>
                  <td>Top Up</td>
                  <td class="text-end"> 
                    <span v-if="formData.topupAmount" class="p-0">{{ selectedmerchantContract.contract_currency }} {{ convertCurrencyFormat(formData.topupAmount || 0, true)}}
                    </span>
                    <span v-else class="p-0">+ {{ selectedmerchantContract.contract_currency }} 0.00
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="border-bottom border-secondary pb-2 align-top">Payin Rate and Extra Charges
                    <span v-if="selectedmerchantContract.payin_rate" class="d-block p-0 font-size-13 text-muted">({{selectedmerchantContract.payin_rate }}% + {{ selectedmerchantContract.contract_currency }} {{ convertCurrencyFormat(selectedmerchantContract.payin_extra_charges,true) }})</span></td>
                    <td class="text-end border-bottom border-secondary pb-2 align-top"> 
                      <span class="p-0" v-if="formData.topupAmount">- {{ selectedmerchantContract.contract_currency }} {{ convertCurrencyFormat(calculateCharge(), true) }}
                      </span> 
                      <span v-else class="p-0">-</span>
                  </td>
                </tr>
                <tr>
                  <td class="pt-2"> Merchant Receives Amount
                  </td>
                  <td class="text-end pt-2"> 
                    <span class="p-0 fw-medium text-success" v-if="calculateAmountReceived()"> = {{ selectedmerchantContract.contract_currency }} {{convertCurrencyFormat(calculateAmountReceived(), true)}} </span>
                    <span v-else class="p-0"> {{ selectedmerchantContract.contract_currency }} 0.00</span>
                  </td>
                </tr>
                </table>
               </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">After Top Up Contract Balance</label>
            <div class="col-lg-9 col-12">
              <div class="bg-light p-3 px-3 rounded">
                <table class="table mb-0">
                <tr>
                  <td>Current Balance</td>
                  <td class="text-end"> 
                    <span class="p-0" v-if="selectedmerchantContract.contract_balance">{{ selectedmerchantContract.contract_currency }} {{ convertCurrencyFormat(selectedmerchantContract.contract_balance, true)}}
                    </span>
                    <span v-else class="p-0"> {{ selectedmerchantContract.contract_currency }} 0.00</span>
                  </td>
                </tr>
                <tr>
                  <td class="border-bottom border-secondary pb-2 align-top">Merchant Receives Amount</td>
                  <td class="text-end border-bottom border-secondary pb-2 align-top"> 
                    <span v-if="calculateAmountReceived()" class="p-0 ">
                      +  {{ selectedmerchantContract.contract_currency }} {{convertCurrencyFormat(calculateAmountReceived(), true)}} 
                    </span>
                    <span v-else class="p-0"> {{ selectedmerchantContract.contract_currency }} 0.00</span>
                  </td>
                </tr>
                <tr>
                  <td class="pt-2">Contract Balance</td>
                  <td class="text-end pt-2"> 
                    <span class="p-0 fw-medium text-success" v-if="formData.topupAmount"> = {{ selectedmerchantContract.contract_currency }} {{calculateBalance()}} </span>
                    <span v-else class="p-0"> -</span>
                  </td>
                </tr>
                </table>
               </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Select Currency</label>
            <div class="col-lg-9 col-12">
              <select class="form-select" aria-label="" v-model="formData.rechargeCurrency" @change="changeCurrency" :class="{
                        'is-invalid':
                        modalSubmit && $v.formData.rechargeCurrency.$error,
                      }" :disabled="!formData.topupAmount">
                <option value="">Select Currency</option>
                <option v-for="(value, index) in rechargeCurrencyList" :value="value" :key="index">
                  {{value.displayName}} 
                  <!-- (Min {{value.shortName}}  {{convertCurrencyFormat(value.minSettlementAmount, true)}}) -->
                </option>
              </select>
              <div v-if="modalSubmit && $v.formData.rechargeCurrency.$error" class="invalid-feedback">
                <span v-if="!$v.formData.rechargeCurrency.required">Please select the currency for withdrawal.</span>
              </div>
              <!-- <div class="fw-medium mt-2 p-3 bg-light text-info rounded" v-if="formData.topupAmountAfterCovertRate!==''">
                <span class="spinner-border spinner-border-sm me-2 border-1" v-if="loading" role="status" aria-hidden="true"></span>
                {{formData.rechargeCurrency.shortName}} {{ convertCurrencyFormat(formData.topupAmountAfterCovertRate,true) }}</div>
               -->
            </div>
          </div>
          <div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label" v-if="formData.rechargeCurrency.shortName!=='USDT'">Sender's Bank</label>
              <label class="col-lg-3 col-12 col-form-label" v-else>Cryptocurrency</label>
              <div class="col-lg-9 col-12">
                <multiselect v-if="banksList.length" v-model="selectedBanks" @input="updateBank" :options="banksList" label="name" class="" group-label="category" group-values="list" placeholder="Select Bank" track-by="name" :close-on-select="true" :allow-empty="false" deselect-label="" select-label="" :show-on-select="true" :disabled="disabled || formData.rechargeCurrency == ''" :class="{
                        'is-invalid':
                        modalSubmit && $v.formData.rechargeCurrency.$error,
                      }">
                  <!-- <template slot="singleLabel" slot-scope="props"><span v-if="props.option.list!==-1">
                        {{ props }}
                        <span class="option__title">{{ props.option.name }}</span></span></template><template slot="option" slot-scope="props"><span v-if="props.option.id!==-1"><span class="option__title">{{ props.option.name }}</span></span></template> -->
                  <span slot="noResult">Oops! No bank found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formData.bankName.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.bankName.required">Sender's Bank is required.</span>
                  <span v-else-if="!$v.formData.bankName.equalDefault">Sender's Bank is required.</span>
                </div>
                <div>
                  <input v-if="!(banksList.length>0)" v-model.trim="formData.bankName" type="tel" class="form-control" placeholder="Sender's Bank" name="bankName" :disabled="banksList.length>0 || formData.rechargeCurrency == '' || formData.rechargeCurrency.shortName=='USDT'" :class="{
                        'is-invalid':
                        modalSubmit && $v.formData.bankName.$error,
                      }" />
                  <div v-if="modalSubmit && $v.formData.bankName.$error" class="invalid-feedback">
                    <span v-if="!$v.formData.bankName.required">Sender's Bank is required.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label" v-if="formData.rechargeCurrency.shortName!=='USDT'"> Sender's Account Number</label>
              <label class="col-lg-3 col-12 col-form-label" v-else>Sender Address</label>
              <div class="col-lg-9 col-12" v-if="formData.rechargeCurrency.shortName!=='USDT'">
                <input v-model.trim="formData.accountNumber" type="tel" class="form-control" placeholder="Account Number" name="accountNumber" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountNumber.$error,
                  }" :disabled="disabled || formData.rechargeCurrency == ''" />
                <div v-if="modalSubmit && $v.formData.accountNumber.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountNumber.required">Sender's Account Number is required.</span>
                </div>
              </div>
              <div class="col-lg-9 col-12" v-else>
                <input v-model.trim="formData.accountNumber" type="text" class="form-control" placeholder="Sender's Address" name="accountNumber" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountNumber.$error,
                  }" :disabled="disabled || formData.rechargeCurrency == ''" />
                <div v-if="modalSubmit && $v.formData.accountNumber.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountNumber.required">Sender's  Address is required.</span>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label" v-if="formData.rechargeCurrency.shortName!=='USDT'">Sender's Account Name</label>
              <label class="col-lg-3 col-12 col-form-label" v-else>Network</label>
              <div class="col-lg-9 col-12" v-if="formData.rechargeCurrency.shortName!=='USDT'">
                <input v-model.trim="formData.accountName" type="text" class="form-control" placeholder="Account Name" name="accountName" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountName.$error,
                  }" :disabled="disabled || formData.rechargeCurrency == ''" />
                <div v-if="modalSubmit && $v.formData.accountName.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountName.required">Sender's Account Name is required.</span>
                </div>
              </div>
              <div class="col-lg-9 col-12" v-else>
                <input v-model.trim="formData.accountName" type="text" class="form-control" placeholder="Network" name="accountName" :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.accountName.$error,
                  }" :disabled="disabled || formData.rechargeCurrency == '' || formData.rechargeCurrency.shortName=='USDT'" />
                <div v-if="modalSubmit && $v.formData.accountName.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.accountName.required">Network is required.</span>
                </div>
              </div>
            </div>
            <!-- <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label">Preferred Transaction Description <span class="text-muted d-block fw-normal">(Optional)</span>
              </label>
              <div class="col-lg-9 col-12">
                <textarea rows="4" v-model.trim="formData.transactionDescription" type="text" class="form-control" placeholder="Preferred Transaction Description" name="transactionDescription" :disabled="disabled || formData.rechargeCurrency == ''"></textarea>
              </div>
            </div> -->
            <hr class="my-3">
            <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Status</label>
            <div class="col-lg-9 col-12">
              
              <select class="form-select" v-model="formData.status" @change="changeStatus">
                <option value="complete">Completed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          <div class="mb-3 row" v-if="formData.status=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Payment Gateway</label>
            <div class="col-lg-9 col-12"> 
                <multiselect
                  v-model="selectedPg"
                  :options="pgList"
                  label="payment_gateway"
                  class="mb-1"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  select-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @input="updatePg()"
                  :class="{ 'is-invalid': modalSubmit && $v.formSuccessData.paymentGateway.$error}"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.id!==''">
                    <span v-if="props.option.status!==1" 
                    class="text-danger me-1">&#9679;</span>
                    <span v-else class="text-success me-1">&#9679;</span>
                    <span class="option__title">{{ props.option.payment_gateway_name }} ({{ props.option.currency }}  {{ convertCurrencyFormat(props.option.pg_balance, true) }} ) 
                      <span class="badge text-bg-primary ms-1 font-size-12">
                        {{props.option.payin_rate}}%
                        + {{ convertCurrencyFormat(props.option.payin_extra_charges, true) }}
                      </span>
                    </span>
                  </span>
                  <span v-else class="font-size-14">
                    {{ props.option.payment_gateway_name }}
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <span v-if="props.option.id!==''">
                    <span v-if="props.option.status!==1" 
                    class="text-danger me-1">&#9679;</span>
                    <span v-else class="text-success me-1">&#9679;</span>
                    <span class="option__title">{{ props.option.payment_gateway_name }} ({{ props.option.currency }}  {{ convertCurrencyFormat(props.option.pg_balance, true) }} ) 
                      <span class="badge text-bg-primary ms-1 font-size-12">
                        {{props.option.payin_rate}}%
                        + {{ convertCurrencyFormat(props.option.payin_extra_charges, true) }}
                      </span>
                    </span>
                  </span>
                  <span v-else class="font-size-14">
                    {{ props.option.payment_gateway_name }}
                  </span>
                </template>
                <span slot="noResult">Oops! No Payment Gateway found. </span>
                </multiselect>
                <div v-if="modalSubmit && $v.formSuccessData.paymentGateway.$error" class="invalid-feedback">
                  <span v-if="!$v.formSuccessData.paymentGateway.required">Payment gateway is required.</span>
                </div>
              </div>
                  
          </div>
          <div class="mb-3 row" v-if="formData.status=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Transaction ID</label>
            <div class="col-lg-9 col-12">
              <input v-model.trim="formSuccessData.transactionId" type="text" class="form-control" placeholder="Transaction ID"  name="TransactionId" :class="{ 'is-invalid':  modalSubmit && $v.formSuccessData.transactionId.$error }" />
              <div v-if="modalSubmit && $v.formSuccessData.transactionId.$error" class="invalid-feedback">
                <span v-if="!$v.formSuccessData.transactionId.required">Transaction ID is required.</span>
              </div>
            </div>
          </div>
          <div class="mb-3 row" v-if="formData.status=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Company Receives Amount</label>
            <div class="col-lg-9 col-12">
              <input v-model.trim="formSuccessData.receivedAmount" type="tel" @input="inputNumberOnly($event, 'formSuccessData','receivedAmount')" class="form-control" placeholder="Company Receives Amount" :class="{ 'is-invalid':  modalSubmit && $v.formSuccessData.receivedAmount.$error }" name="receivedAmount" />
              <div v-if="modalSubmit && $v.formSuccessData.receivedAmount.$error" class="invalid-feedback">
                <span v-if="!$v.formSuccessData.receivedAmount.required">Received Amount is required.</span>
              </div>
            </div>
          </div>
          <div class="mb-3 row" v-if="formData.status=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Currency Rate</label>
            <div class="col-lg-9 col-12">
              <input v-model.trim="formSuccessData.currencyRate" type="text" class="form-control" placeholder="Currency Rate (1 USDT = 4.67 MYR)" :class="{'is-invalid': modalSubmit && $v.formSuccessData.currencyRate.$error, }" name="currencyRate" />
              <div v-if="modalSubmit && $v.formSuccessData.currencyRate.$error" class="invalid-feedback">
                <span v-if="!$v.formSuccessData.currencyRate.required">Transaction Rate is required.</span>
              </div>
            </div>
          </div>
           <div class="mb-3 row" v-if="formData.status=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Transaction Remark</label>
            <div class="col-lg-9 col-12">
              <textarea v-model.trim="formSuccessData.transactionRemarks" type="text" class="form-control" placeholder="Transaction Remark (e.g. Merchant transfer info, Wallet Address)" rows="3" ></textarea>
            </div>
          </div>
          <div class="mb-3 row" v-if="formData.status=='complete'">
            <label class="col-lg-3 col-12 col-form-label">Transaction Receipts <span class="d-block font-size-13 fw-normal text-muted">(Max. {{maxFiles}} Files)</span> 
              <div><a href="https://combinepdf.com" class="fw-normal" target="_blank"><u>https://combinepdf.com</u></a></div>
            </label>
            <div class="col-lg-9 col-12">
              <input class="form-control" :class="{ 'border border-danger animate__animated animate__shakeX is-invalid': modalSubmit && $v.formSuccessData.transactionReceipt.$error}" multiple accept="application/pdf, image/*" type="file" id="formFile" @change="onChangePDF" :disabled="disabled || currentFiles==maxFiles">

              <div v-if="modalSubmit && $v.formSuccessData.transactionReceipt.$error" class="invalid-feedback">
                <span v-if="!$v.formSuccessData.transactionReceipt.required">Please upload transaction receipts.</span>
              </div>
                <div class="mt-3">
                  <a :href="value.link" class="mb-1 d-block" v-for="(value,index) in formSuccessData.transactionReceipt" :key="index" target="_blank"><i class="uil uil-receipt-alt text-primary"></i> {{ value.file_name}} ({{ value.file_size }})</a>
                </div>
                <div class="mt-2">
                  <div class="Animated progress rounded bg-white" style="height:16px" role="progressbar" :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar progress-bar-striped progress-bar-animated rounded fw-medium font-size-13" :style="`width: ${uploadProgress}%`">{{uploadProgress}}%</div>
                </div>
                </div>
            </div>
          </div>
          <div class="mb-3 row" v-if="formData.status=='rejected'">
            <label class="col-lg-3 col-12 col-form-label">Reasons for Rejection</label>
            <div class="col-lg-9 col-12">
              <textarea rows="4" v-model.trim="formRejectedData.reasonForRejected" type="text" class="form-control mb-1" :class="{
                    'is-invalid':
                    modalSubmit && $v.formRejectedData.reasonForRejected.$error,
                  }" placeholder="Reasons for Rejection" name="reasonForRejection"></textarea>
              <div v-if="modalSubmit && $v.formRejectedData.reasonForRejected.$error" class="invalid-feedback">
                <span v-if="!$v.formRejectedData.reasonForRejected.required">Reasons for rejection is required.</span>
              </div>
              <div class="mt-3">
                <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Insufficient Balance At Contract. '"><i class="uil uil-plus-circle"></i> Insufficient Balance At Contract</button>
                <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Incorrect Account Name. '"><i class="uil uil-plus-circle"></i> Incorrect Account Name</button>
                <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Incorrect Account Number. '"><i class="uil uil-plus-circle"></i> Incorrect Account Number</button>
                <button class="btn btn-sm bg-soft-danger text-danger me-1 mb-2" @click="formRejectedData.reasonForRejected+='Account Does Not Exist. '"><i class="uil uil-plus-circle"></i> Account Does Not Exist</button>
                <button class="btn btn-sm bg-soft-secondary text-secondary me-1 mb-2" @click="formRejectedData.reasonForRejected=''"><i class="uil uil-times-circle"></i> Clear</button>
              </div>
            </div>
          </div>
            <hr>
            <div class="d-grid gap-2 d-md-block text-end">
              <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-topup'].hide()">Cancel</button>
              <button type="button" class="btn fw-medium btn-info" @click="submit" :disabled="modalLoading || disabled">
                <span v-if="modalLoading"> Submitting...</span>
                <span v-else> Submit </span>
                <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import {
    required,
  } from "vuelidate/lib/validators";
  //const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  // const validWebsiteUrl = (value) => {
  //   console.log(value);
  //   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
  //   return regex.test(value);
  // }
  // const validUsername = (value) => {
  //   const regex = /^[a-z0-9]+$/;
  //   return regex.test(value);
  // };
  const equalDefault = (value) => {
    return value !== -1;
  }
  /**
   * Starter page
   */
  const BankDefault = []
  const pgDefault = { "id": "", "payment_gateway":"", "payment_gateway_name": "Select Payment Gateway", "payment_gateway_code": "", "currency":"", "status":"", "payin_rate": 0, "payin_extra_charges": 0 } 
  const MerchantDefault = {
    "id": "",
    "account_db_code": "all",
    "account_name": "All Merchants",
    "status": "active",
    "reseller_name": "",
    "reseller_id": "",
    "merchant_code": ""
  }
  const MerchantContractDefault = {
    "status": "0",
    "payin_rate": 0,
    "contract_id": "-1",
    "payout_rate": 0,
    "total_payin": 0,
    "ewallet_rate": 0,
    "total_payout": 0,
    "contract_name": "Select Contract",
    "contract_total": 0,
    "contract_status": "",
    "settlement_rate": 0,
    "contract_balance": 0,
    "total_settlement": 0,
    "contract_currency": "",
    "payin_extra_charges": 0,
    "payout_extra_charges": 0,
    "ewallet_extra_charges": 0,
    "settlement_extra_charges": 0,
    "contract_list_length_total": 0,
    "merchant_Id": "",
    "account_name": "",
    "merchant_status": "",
    "reseller_id": "",
    "reseller_name": "",
    "reseller_code": "",
    "reseller_level": 0
}
  export default {
    components: {
      Multiselect,
      Common
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        currencyMin: 0,
        currencyMax: 0,
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        selectedmerchant: MerchantDefault,
        merchant: [MerchantDefault],
        selectedmerchantContract: MerchantContractDefault,
        merchantContract: [MerchantContractDefault],
        selectedBanks: [],
        banksList: [],
        rechargeCurrencyList: [],
        currencyChargePercentage: 0,
        loading: false,
        disabled: false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        returnData: [],
        selectedPg:pgDefault,
        pgList:[pgDefault],
        formData: {
          merchantId: "",
          contractId: "",
          status: "complete",
          rechargeCurrency: "",
          bankName: "",
          accountNumber: "",
          accountName: "",
          topupAmount: "",
        },
        formSuccessData: {
          transactionRemarks: "",
          currencyRate: "",
          receivedAmount: "",
          transactionId: "",
          paymentGateway: "",
          transactionReceipt: [],
        },
        formRejectedData: {
          reasonForRejected: "",
        },
        uploadProgress:0,
        currentFiles: 0,
        uploadHowManyPerTime:0,
        maxFiles: 3,
        minTopup:[]
      };
    },
    validations: {
      formData: {
        merchantId: {
          required,
          equalDefault
        },
        contractId: {
          required,
          equalDefault
        },
        bankName: {
          required,
        },
        rechargeCurrency: {
          required,
        },
        accountNumber: {
          required,
        },
        accountName: {
          required,
        },
        topupAmount: {
          required,
          minTopup(value) {
            return (value >= parseFloat(this.currencyMin));
          }
        },
        // topupAmountAfterCovertRate: {
        //   required,
        //   minSettlement(value) {
        //     return (value >= parseFloat(this.currencyMin));
        //   },
        // },
      },
      formSuccessData: {
        transactionReceipt: {
          required,
        },
        transactionId: {
          required,
        },
        receivedAmount: {
          required,
        },
        currencyRate: {
          required,
        },
        paymentGateway: {
          required,
        },
      },
      formRejectedData: {
        reasonForRejected: {
          required,
        }
      }
    },
    middleware: "authentication",
    async mounted() {
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      this.accessToken = this.$refs.commonFunc.getToken()
      this.accessUsername = this.$refs.commonFunc.getUsername()
      //   await this.getData();
      await this.getPgList();
    },
    created() {},
    methods: {
      async getPgList(){
        console.log("getPgList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getPgListShort',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.selectedPg = pgDefault
              this.pgList = [this.selectedPg]
              resData.pg_list.forEach(element => {
                if (element.status!=='disabled'){
                this.pgList.push({
                    id: element.id,
                    payment_gateway:`${element.payment_gateway_name} (${element.currency})`,
                    payment_gateway_name: element.payment_gateway_name,
                    payment_gateway_code: element.payment_gateway_code,
                    currency: element.currency,
                    pg_balance: element.pg_balance,
                    payin_rate: element.payin_rate,
                    payin_extra_charges: element.payin_extra_charges,
                    status:(element.status  === 'activated') ? 1 : 0,
                  })
                }
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      updateBank() {
        this.formData.bankName = this.selectedBanks.name
      },
      updatePg() {
        if (this.formData.status=='complete' && this.selectedPg.payment_gateway_code !=='') {
          this.formSuccessData.paymentGateway=this.selectedPg
        }else{
          this.formSuccessData.paymentGateway=""
        }
      },
      async onChangePDF(e) {
        console.log(this.currentFiles,this.maxFiles);
        console.log(e.target.files.length);
        if ((e.target.files.length+this.currentFiles)> this.maxFiles) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `The system allows a maximum of ${this.maxFiles} file uploads.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          e.target.value = '';
          return; // Abort further processing if the file limit is exceeded
        }

        // Iterate over each file
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          // Check if the file size is greater than 5MB
          if (file.size > 5 * 1024 * 1024) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `The file ${file.name} exceeds the 5MB size limit.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            });
            e.target.value = '';
            return; // Abort further processing if any file size exceeds 5MB
          }

          // Upload each file
          await this.uploadFile(file, e.target.files.length);
        }
      },
      convertFileSize(fileSize) {
        var formattedFileSize = ''
        if (fileSize < 1024) {
          formattedFileSize = fileSize + ' bytes';
        } else if (fileSize < 1048576) { // 1024 * 1024
          formattedFileSize = (fileSize / 1024).toFixed(2) + ' KB';
        } else if (fileSize < 1073741824) { // 1024 * 1024 * 1024
          formattedFileSize = (fileSize / 1048576).toFixed(2) + ' MB';
        } else {
          formattedFileSize = (fileSize / 1073741824).toFixed(2) + ' GB';
        }
        return formattedFileSize
      },
      async uploadFile(file, howManyFiles) {
        this.$Progress.start();
        this.disabled = true;
        const bodyFormData = new FormData();
        bodyFormData.append('accessToken', this.accessToken);
        bodyFormData.append('accessUsername', this.accessUsername);
        bodyFormData.append('media', file);
        var postUrl = appConfig.APIHostAdmin + 'controller/media/saveMediaFile'
        try {
          const response = await axios.post(postUrl, bodyFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            },
          });

          const resData = response.data;
          if (resData.status === 200) {
            // Add the URL to your array or handle the success as needed
            this.formSuccessData.transactionReceipt.push(
              {
                'file_name':file.name,
                'file_size': this.convertFileSize(file.size),
                'link': resData.media_path
              })
            
            this.currentFiles = this.currentFiles + 1
            this.uploadHowManyPerTime = this.uploadHowManyPerTime + 1
            if (howManyFiles==this.uploadHowManyPerTime){
              document.getElementById('formFile').value = ''
              this.uploadHowManyPerTime = 0
            }
          } else if (resData.status === 440) {
            // Handle 440 status code
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            });
            localStorage.clear();
            this.$router.push({
              path: '/login',
            });
          } else {
            // Handle other status codes
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            });
          }
        } catch (error) {
          // Handle the error
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
        } finally {
          this.disabled = false;
          this.uploadProgress = 0;
          this.$Progress.finish();
        }
      },

      calculateAmountReceived(){
        var amount = this.formData.topupAmount || 0
        var charges = this.calculateCharge()
        var total = 0.00
        if (amount){
          total = amount - charges
        }
        return total
      },
      calculateCharge(){
        var amount = this.formData.topupAmount || 0
        var charges = 0.00
        if (amount){
          charges = ( parseFloat(amount) * parseFloat((this.selectedmerchantContract.payin_rate/100))) + parseFloat(this.selectedmerchantContract.payin_extra_charges)
        }
        return charges
      },
      calculateBalance(){
        var merchantReceivesAmount = this.calculateAmountReceived();
        var balance = this.selectedmerchantContract.contract_balance;
        console.log(merchantReceivesAmount,balance)
        balance =  parseFloat(balance) + parseFloat(merchantReceivesAmount)
        balance = this.convertCurrencyFormat(balance,true)
        return balance
      },
      inputNumberOnly(event, parentModel, chilModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][chilModel] = numericValue;
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-topup'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.getMerchant();
        this.getRechargeCurrencyList();
      },
      changeMerchant() {
        // this.selectedBanks=BankDefault;
        this.formData.merchantId = this.selectedmerchant.account_db_code;
        this.formData.rechargeCurrency = '';
        this.formData.topupAmount = '';
        //this.formData.topupAmountAfterCovertRate = '';
        this.formData.bankName = '';
        this.getMerchantContract();
      },
      changeContract() {
        this.currencyMax = this.selectedmerchantContract.contract_balance;
        this.formData.contractId = this.selectedmerchantContract.contract_id;
        this.formData.rechargeCurrency = '';
        this.formData.topupAmount = '';
       //this.formData.topupAmountAfterCovertRate = ''
        this.formData.bankName = '';
        if (this.minTopup.length){
          this.currencyMin = this.minTopup.find(element => element.currency==this.selectedmerchantContract.contract_currency).amount || 0
        }
      },
      changeStatus() {
        if (this.formData.status=='complete'){
          this.formSuccessData= {
            transactionRemarks: "",
            currencyRate: "",
            receivedAmount: "",
            transactionId: "",
            transactionReceipt: "",
            paymentGateway: ""
          }
        }else{
          this.formRejectedData= {
            reasonForRejected: "",
          }
        }
        this.modalSubmit = false
      },
      changeCurrency() {
        this.selectedBanks = BankDefault;
        this.formData.bankName = ""
        this.formData.accountName = ""
        //this.currencyMin = this.formData.rechargeCurrency.minSettlementAmount
        this.formData.contractId = this.selectedmerchantContract.contract_id;

        if (this.formData.rechargeCurrency.shortName=='USDT'){
          this.formData.bankName = "USDT"
          this.formData.accountName = this.formData.rechargeCurrency.displayName
        }else{
          this.formData.bankName = ""
          this.formData.accountName = ""
        }
        this.getBankList();
        // if (this.formData.rechargeCurrency.contract_currency && this.selectedmerchantContract.contract_currency && this.formData.topupAmount){
        //   if (this.formData.rechargeCurrency.contract_currency !== this.selectedmerchantContract.contract_currency){
        //     this.convertCurrencyRates(this.formData.rechargeCurrency.contract_currency,this.selectedmerchantContract.contract_currency,this.formData.topupAmount)
        //   }else{
        //     //this.formData.topupAmountAfterCovertRate = this.formData.topupAmount
        //   }
         
        // }
        this.modalSubmit = false
        this.$v.formData.$touch();
       
      },
      getBankList() {
        console.log("getBankList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("currency", this.formData.rechargeCurrency.shortName);
        var posturl = ""
        if (this.formData.rechargeCurrency.shortName == 'MYR') {
          posturl = "/api/bank_list_my.json"
        } else if (this.formData.rechargeCurrency.shortName == 'IDR') {
          posturl = "/api/bank_list_id.json"
        }else if (this.formData.rechargeCurrency.shortName == "IDR_NATIVE") {
          posturl = "/api/bank_list_id.json";
        }else if (this.formData.rechargeCurrency.shortName == 'VND') {
          posturl = "/api/bank_list_vn.json"
        } else if (this.formData.rechargeCurrency.shortName == "INR") {
          posturl = "/api/bank_list_in.json";
        }else if (this.formData.rechargeCurrency.shortName == "INR_NATIVE") {
          posturl = "/api/bank_list_in.json";
        }else if (this.formData.rechargeCurrency.shortName == "BRL") {
          posturl = "/api/bank_list_brl.json";
        }else {
          this.banksList = []
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          return
        }
        axios({
          method: "get",
          url: posturl,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }).then((response) => {
          var resData = response.data;
          console.log(resData.result)
          if (resData.status == 200) {
            this.selectedBanks = BankDefault
            this.banksList = [this.selectedBanks]
            const popular = []
            const other = []
            resData.result.forEach(element => {
              if (element.category === 'popular') {
                popular.push(element);
              } else {
                other.push(element);
              }
            });
            this.banksList = [{
              category: 'Popular',
              list: popular
            }, {
              category: 'Other',
              list: other
            }]
            this.selectedBanks = this.banksList[0].list[0];
            this.formData.bankName = this.selectedBanks.name;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      getMerchant() {
        console.log("getMerchant")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.merchant = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "999999");
        bodyFormData.append("resellerId", 'all');
        bodyFormData.append("searchMerchant", '');
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getAllMerchantList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.merchant = [];
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total;
              var responseData = resData.data;

              responseData.forEach(element => {
                  this.merchant.push(element)
              });
              if (this.merchant.length > 0) {
                this.selectedmerchant = this.merchant[0]
                this.changeMerchant()
              }
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              this.loading = false;
              this.disabled = false
            }
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
      },
      getMerchantContract() {
        console.log("getMerchantContract");
        this.$Progress.start();
        this.loading = true;
        this.disabled = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999999");
        bodyFormData.append("reseller",  "all" );
        bodyFormData.append("merchant", this.selectedmerchant.account_db_code);
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getContractListForMerchant',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              this.selectedmerchantContract = []
              this.merchantContract = []
                resData.data.forEach((element) => {
                  this.merchantContract.push(element);
                });
                if (this.merchantContract.length > 0) {
                  this.selectedmerchantContract = this.merchantContract[0]
                  this.currencyMax = parseFloat(this.selectedmerchantContract.contract_balance)
                }
                this.changeContract()
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      },
      getRechargeCurrencyList() {
        console.log("getRechargeCurrencyList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        const uniqueParam = `nocache=${new Date().getTime()}`;
        axios({
          method: "get",
          url: '/api/recharge_currency.json?' + uniqueParam,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.rechargeCurrencyList = []
            this.currencyMin = 0.00
            this.currencyChargePercentage = 0
            resData.localBankTransfer.forEach((element) => {
              this.rechargeCurrencyList.push(element);
            });
            // resData.internationalBankTransfer.forEach((element) => {
            //   this.rechargeCurrencyList.push(element);
            // });
            resData.cryptoTransfer.forEach((element) => {
              this.rechargeCurrencyList.push(element);
            });

            this.minTopup = resData.minTopup;

          } else if (resData.status == 440) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${resData.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
        }).catch((error) => {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        });
      },
      resetModal() {
        this.selectedmerchant = MerchantDefault
        this.selectedmerchantContract = MerchantDefault
        this.selectedBanks = BankDefault
        this.modalSubmit = false;
        this.modalLoading = false;
        this.minTopup=[];
        this.formData= {
          merchantId: "",
          contractId: "",
          status: "complete",
          rechargeCurrency: "",
          bankName: "",
          accountNumber: "",
          accountName: "",
          topupAmount: "",
        }
        this.formSuccessData= {
          transactionRemarks: "",
          currencyRate: "",
          receivedAmount: "",
          transactionId: "",
          transactionReceipt: [],
          paymentGateway: ""
        }
        this.formRejectedData= {
          reasonForRejected: "",
        }
        this.currentFiles= 0;
        this.selectedPg = pgDefault
      },
      switchVisibility() {
        (this.classicon = this.classicon === "uil uil-eye-slash" ? "uil uil-eye" : "uil uil-eye-slash"),
        (this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password");
      },
      submit() {
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log(this.$v.formData.$invalid, this.$v.formSuccessData.$invalid, this.$v.formRejectedData.$invalid )
        if (this.formData.status=="complete"){
          this.$v.formSuccessData.$touch();
          if (this.$v.formSuccessData.$invalid) {
            return;
          }
        }
        console.log(this.$v.formData.$invalid, this.$v.formSuccessData.$invalid, this.$v.formRejectedData.$invalid )
        if (this.formData.status=="rejected"){
          this.$v.formRejectedData.$touch();
          if (this.$v.formRejectedData.$invalid) {
            return;
          }
        }
        console.log(this.$v.formData.$invalid, this.$v.formSuccessData.$invalid, this.$v.formRejectedData.$invalid )

        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          const files = []
          this.formSuccessData.transactionReceipt.forEach(element => {
            files.push(element.link)
          });
          bodyFormData.append('accessUsername', this.accessUsername);
          bodyFormData.append('accessToken', this.accessToken);
          bodyFormData.append('merchantId', this.formData.merchantId);
          bodyFormData.append('merchantContractId', this.formData.contractId);
          bodyFormData.append('topUpAmount', this.formData.topupAmount);
          bodyFormData.append('receivedAmount', this.formSuccessData.receivedAmount);
          bodyFormData.append('currency', this.selectedmerchantContract.contract_currency);
          bodyFormData.append('rechargeCurrency', JSON.stringify(this.formData.rechargeCurrency));
          bodyFormData.append('currencyRate', this.formSuccessData.currencyRate);
          bodyFormData.append('senderBank', this.formData.bankName);
          bodyFormData.append('senderBankAcc', this.formData.accountNumber);
          bodyFormData.append('senderBankAccName', this.formData.accountName);
          bodyFormData.append('bankTransferList', JSON.stringify(files));
          bodyFormData.append('transactionId',this.formSuccessData.transactionId);
          bodyFormData.append('paymentGateway', JSON.stringify(this.formSuccessData.paymentGateway));
          bodyFormData.append('status', this.formData.status);
          bodyFormData.append('remark',  this.formSuccessData.transactionRemarks);
          bodyFormData.append('rejectedReason', this.formRejectedData.reasonForRejected);
         
          axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/addManualTopUp',
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Top Up Success',
                html: `Your top up request has been successful!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-topup'].hide()
                this.$emit('callParentFunction');
              })
            } else if (resData.status == 440) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          }).catch((error) => {
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.formData.apiID = data.apiID;
            this.formData.apiHash = data.apiHash;
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3 {
    position: absolute;
    top: 4px;
    padding: 5px 10px;
    right: 5px;
    text-align: center;
    width: 32px;
    color: #999;
    z-index: 3;
    background-color: #fff;
    cursor: pointer;
  }
</style>