<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="uil uil-top-arrow-from-top display-6 text-primary lh-1"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }}
                </h4>
              </div>

              <div class="ms-auto">
                <!--  @click="$refs.modalFunc.showModal()" -->
                <button class="btn btn-outline-info fw-medium" v-if="create_modify_permission" @click="$refs.modalFunc.showModal()">
                  <i class="uil uil-plus font-size-16 me-1"></i> Create Top Up
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label mb-1">Merchant Contract</label>
                <multiselect
                  ref="select"
                  v-model="selectedmerchantContract"
                  :options="merchantContract"
                  label="account_name"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="handlePageChange(1)"
                >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title"> {{ props.option.account_name }}: {{ props.option.contract_name }} 
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id!==''">
                    <span v-if="props.option.contract_status=='unavailable'" class="text-warning me-1">&#9679;</span>
                    <span v-else-if="props.option.contract_status=='available'" class="text-success me-1">&#9679;</span>
                    <span class="option__title">  {{ props.option.account_name }}: {{ props.option.contract_name }} 
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
                </multiselect>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-12 col-md-4 px-md-1 mb-3">
                    <label class="form-label mb-1">Processed By</label>
                    <multiselect
                      v-model="selectedUsers"
                      :options="usersList"
                      label="name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="handlePageChange(1)"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.username !== ''">
                          <span class="option__title">{{
                            props.option.name
                          }}</span>
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.username !== ''">
                          {{ props.option.name }}
                        </span>
                      </template>
                      <span slot="noResult">Oops! No user found. </span>
                    </multiselect>
                  </div>
                  <div class="col-12 col-md-4 px-md-1 mb-3">
                    <label class="form-label mb-1">Status</label>
                    <select
                      class="form-select pe-5"
                      id="1"
                      v-model="selectedStatus"
                      @change="handlePageChange(1)"
                      :disabled="disabled"
                    >
                      <option value="">All Status</option>
                      <option value="complete">Completed</option>
                      <option value="rejected">Rejected</option>
                      <!-- <option value="revoked">Revoked</option> -->
                    </select>
                  </div>
                  <!-- <div class="col-12 col-md-4 px-md-1 mb-3">
                    <label class="form-label mb-1">Country</label>
                    <multiselect
                      v-model="selectedCountry"
                      :options="countryList"
                      label="name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="countryChanged"
                    >
                      <span slot="noResult">Oops! No Country found. </span>
                    </multiselect>
                  </div> -->
                </div>
              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-12 col-xl">
                <label class="form-label mb-1">Top Up ID</label>
                <input
                    type="text"
                    placeholder="Top Up Id"
                    class="form-control mb-3"
                    :disabled="disabled"
                    v-model.trim="searchTopupId"
                  />
              </div>
              <div class="col-12 col-md col-xl mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
              </div>

              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <table
                id="freeze"
                class="table mt-2 mb-0 align-middle min-width-760"
                :class="{ 'table-hover': returnData.length > 0 }"
              >
                <thead class="text-uppercase">
                  <tr>
                    <th>Top Up Id</th>
                    <th>Created Date<br>Updated Date</th>
                    <th v-if="account_type=='admin'">Merchant Code<br>Merchant Name</th>
                    <th>Merchant Contract<br>Contract Currency</th>
                    <th>Sender's Bank/Crypto<br>Recharge currency</th>
                    <th>Account Name/Network<br>Account No./Wallet Address</th>
                    <th>Top Up Amount<br> Company Receives Amount</th>
                    <th>Currency Rate<br>Transaction Remarks</th>
                    <th>Total Charges<br>Merchant Receives Amount</th>
                    <th>Transaction Id /<br />Transaction Receipt</th>
                    <th>Status</th>
                    <th v-if="account_type=='admin'">Processed By</th>
                    <th v-if="create_modify_permission">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td  :colspan="(account_type === 'admin') || create_modify_permission ? 13 : 11" height="300" class="text-center text-muted">
                      <i class="uil uil-top-arrow-from-top me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td  :colspan="(account_type === 'admin') || create_modify_permission? 13 : 11" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr v-for="(value, index) in returnData" :key="index">
                    
                    <td>
                      <a href="javascript:void(0)" @click="clickExtraInfo(`Top Up Detail #${value.topup_id }`, value)"
                      :title="`${value.topup_id}`">
                        {{ value.topup_id }}
                      </a>
                    </td>
                    <td>
                      {{ value.created_at }}<br>
                      {{ value.updated_at }}
                    </td>
                    <td>
                      {{ value.merchant_code }}<br>
                      {{ value.merchant_name }}
                    </td>
                    <td>
                      {{ value.contract_name }}<br>
                      {{ value.contract_currency }}
                    </td>
                    <td>
                      {{ value.sender_bank }}<br>
                      {{ value.recharge_currency }}
                    </td>
                    <td> 
                      {{ value.sender_account_name }}<br>
                      {{ value.sender_account_number }}
                    </td>
                    <td>
                      {{ value.contract_currency }} {{ convertCurrencyFormat(value.topup_amount,true)}} <br>
                      {{ value.recharge_currency }} <span v-if="value.company_received_amount!==''">{{  convertCurrencyFormat(value.company_received_amount || '-',true) }}</span><span v-else>-</span>
                    </td>
                    <td>
                      <small class="d-block text-muted" v-if="value.currency_rate"> 
                        Currency Rate
                      </small>
                      <div v-else>-</div>
                      
                      <span class="d-block font-size-14" v-if="value.currency_rate">{{ value.currency_rate }}</span>
                      <a href="javascript:void(0)" 
                        class="badge bg-soft-info font-size-12"
                        v-if="value.transaction_remarks"
                        @click="clickExtraInfo('Transaction Remarks', value, 'remark')"
                        >Remark <i class="uil uil-question-circle"></i></a
                      >
                      <span v-else>-</span>
                     
                    </td>
                    <td> 
                      {{ value.contract_currency }}  {{  convertCurrencyFormat(value.payin_total_charges, true) }}  ({{ value.payin_rate }}% +
                      {{ value.contract_currency }} {{ value.payin_extra_charges}})
                      <br>
                      {{ value.contract_currency }}  <span v-if="value.merchant_received_amount!==''">{{  convertCurrencyFormat(value.merchant_received_amount || '-',true) }}</span><span v-else>-</span>
                    </td>
                    <td>
                      <div class="mb-1">{{ value.transaction_id || '-' }}</div>
                      <span v-for="(v, i) in value.transfer_receipts" :key="i" class="d-block">
                      <a
                          v-if="v !==''"
                          :href="v"
                          target="_blank"
                          title="View Receipt"
                          ><i
                            class="uil uil-receipt-alt font-size-16 text-primary"
                          ></i>
                          View Receipt ({{ i+1 }})</a
                        >
                      </span>
                       
                    </td>
                    <td>
                      <span
                        class="badge bg-soft-success fw-medium font-size-13"
                        v-if="value.status == 'complete'"
                        >Completed</span
                      >
                    
                      <span
                        class="badge bg-soft-danger font-size-13"
                        v-else-if="value.status == 'rejected'"
                        v-b-tooltip.hover
                        :title="value.reason"
                        >Rejected <i class="uil uil-question-circle"></i></span
                      >
                    </td>
                    
                    <td v-if="account_type=='admin'" class="text-center">
                      {{value.processed_by }}
                    </td>
                    <td class="text-center" v-if="create_modify_permission">
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="clickExtraInfo(`Top Up Detail #${value.topup_id }`, value)">
                      <i class="uil uil-eye"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
    <createTopup ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken}" @callParentFunction="handlePageChange(currentPage)"/>
    <extraInfo ref="modalFunc3" :data="{ 'extraInfo': currentClickedInfo}"/>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import createTopup from "@/views/pages/app/modal/createTopup";
import extraInfo from "@/views/pages/app/modal/extraInfo";
/**
 * Page
 */
const PageTitle = "Top Up";
const usersDefault = {
  username: "",
  name: "All Users",
};
const BankDefault = {
  id: -1,
  name: "All Banks",
  countryId: 0,
  bankCode: "",
  websiteURL: "",
  isActive: 1,
  category: "",
  isSuspend: 0,
  attachment: null,
  tranCount: 0,
  successTranCount: 0,
  tranPercentage: 0,
  createdAt: "",
};
const CountryDefault = {
  id: -1,
  name: "All Countries",
  currencyId: 0,
  currencyName: "",
  timeZone: [],
};
const MerchantDefault = {
    "status": "0",
    "payin_rate": 0,
    "contract_id": "",
    "payout_rate": 0,
    "total_payin": 0,
    "ewallet_rate": 0,
    "total_payout": 0,
    "contract_name": "All Contracts",
    "contract_total": 0,
    "contract_status": "",
    "settlement_rate": 0,
    "contract_balance": 0,
    "total_settlement": 0,
    "contract_currency": "",
    "payin_extra_charges": 0,
    "payout_extra_charges": 0,
    "ewallet_extra_charges": 0,
    "settlement_extra_charges": 0,
    "contract_list_length_total": 0,
    "merchant_Id": "",
    "account_name": "",
    "merchant_status": "",
    "reseller_id": "",
    "reseller_name": "",
    "reseller_code": "",
    "reseller_level": 0
}

const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    Multiselect,
    createTopup,
    extraInfo
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type:"",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      currentClickedInfo:{title: "", info: {}, type:""},
      submitted: false,
      loading: false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      currencySymbol: "₹",
      selectedmerchantContract: MerchantDefault,
      merchantContract: [MerchantDefault],
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedBanks: BankDefault,
      banksList: [BankDefault],
      selectedUsers: usersDefault,
      usersList: [usersDefault],
      seletedMethod: "",
      selectedStatus: "",
      searchTopupId: "",
      searchMerchantItemId: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      create_modify_permission:false
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.date_range = this.$refs.commonFunc.last30Days();
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
    this.getMerchantContract();
    //this.getCountries();
    this.getUserList();
    await this.handlePageChange(1);
  },
  created() {},
  methods: {
    clickExtraInfo(title, object, type){
      this.currentClickedInfo = {title: '', info: {}, type: ""};
      this.currentClickedInfo = {title: title, info: object, type: type}
      setTimeout(() => this.$refs.modalFunc3.showExtraInfoModal(), 0)
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this[parentModel] = numericValue;
    },
    countryChanged() {
      //this.selectedBanks=BankDefault
      this.handlePageChange(1);
    },
    returnBankList() {
      if (this.selectedCountry.id !== -1) {
        return this.banksList.filter(
          (bank) => bank.countryId === this.selectedCountry.id
        );
      } else {
        return this.banksList;
      }
    },
    getBankList() {
      console.log("getBankList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "/api/bank-list.json",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          console.log(resData.result);
          if (resData.status == 200) {
            this.selectedBanks = BankDefault;
            this.banksList = [this.selectedBanks];
            const isSuspend = [];
            resData.result.forEach((element) => {
              if (element.isSuspend === 1) {
                isSuspend.push(element);
              } else {
                this.banksList.push(element);
              }
            });
            isSuspend.forEach((element) => {
              this.banksList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getUserList() {
      console.log("getUserList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllUserList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedUsers = usersDefault;
            this.usersList = [this.selectedUsers];
            resData.data.forEach((element) => {
              this.usersList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getCountries() {
      console.log("getCountries");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "get",
        url: "/api/countries.json",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            (this.selectedCountry = CountryDefault),
              (this.countryList = [this.selectedCountry]);
            resData.result.forEach((element) => {
              this.countryList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getMerchantContract() {
        console.log("getMerchantContract");
        this.$Progress.start();
        this.loading = true;
        this.disabled = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "9999999");
        bodyFormData.append("reseller", 'all' );
        bodyFormData.append("merchant", "all");
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getContractListForMerchant',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            var resData = response.data;
            if (resData.status == 200) {
              this.selectedmerchantContract = MerchantDefault
              this.merchantContract = [this.selectedmerchantContract]
             
              resData.data.forEach(element => {
                this.merchantContract.push(element)
              });
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.loading = false;
            this.disabled = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum);
      //this.exportData(1)
    },

    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.selectedCountry = CountryDefault;
      this.selectedmerchantContract = MerchantDefault;
      this.selectedBanks = BankDefault;
      this.seletedMethod = "";
      this.selectedStatus = "";
      this.searchTopupId = "";
      this.searchMerchantItemId = "";
      this.filterType = "created_date";
      this.searchAmount = "";
      this.date_range = this.$refs.commonFunc.last30Days();

      this.search_value = "";
      this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = [];
      objectMaxLength.forEach((value, index) => {
        console.log(value);
        wscols.push({ width: objectMaxLength[index] });
      }),
        (ws["!cols"] = wscols);

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name);
      window.XLSX.writeFile(wb, title + "." + (type || "xlsx"));
    },
    async getData(pages) {
      if(cancel !== undefined) cancel();
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("status", this.selectedStatus);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);
      bodyFormData.append("merchantContractId", this.selectedmerchantContract.contract_id);
      bodyFormData.append("processBy", this.selectedUsers.username);
      bodyFormData.append("topUpId", this.searchTopupId);
      
      console.log(
        this.selectedmerchantContract.contract_id,
        this.seletedMethod,
        this.selectedStatus,
        this.selectedCountry.id,
        this.selectedUsers.processedById,
        this.searchTopupId,
        this.searchMerchantItemId,
        this.searchAmount,
        this.filterType,
        this.date_range[0],
        this.date_range[1]
      );
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllTopUpList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken(c => cancel = c)
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
              this.returnData = [];
              console.log(resData)
              this.returnData = resData.data
              this.totalData = resData.total
          }
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
              });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          console.log(error)
        });
    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone", this.accessPhone);
      // bodyFormData.append("page", pages);
      // bodyFormData.append("limit", this.perPage);
      axios({
        method: "get",
        url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          this.totalData = resData.total;
          var users = resData.users;

          for (var i in users) {
            // Combine date and time
            const depositDateTime = `29 Jun 2023, 12:30:00`;
            // Generate a random deposit amount
            const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

            // Calculate merchant fee and nett amount
            const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
            const merchantFee = Math.floor(
              randomDeposit * merchantFeePercentage
            );
            const nettAmount = randomDeposit - merchantFee;
            const randomStatus = Math.random() < 0.8 ? "success" : "failed";
            var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
            var transaction_id = "";
            if (randomMode == "UPI") {
              transaction_id = "320020673657";
            } else {
              transaction_id = "IMPS/123443211240";
            }

            const listData = {
              id: "ids",
              deposit_date: depositDateTime,
              customer_id: `${users[i].id}`,
              customer_name: `${users[i].firstName} ${users[i].lastName}`,
              deposit_amount: randomDeposit.toString(),
              deposit_mode: randomMode.toString(),
              merchant_fee: merchantFee.toString(),
              nett_amount: nettAmount.toString(),
              transaction_id: transaction_id,
              status: randomStatus,
            };
            this.export_data.splice(i, 0, listData);
          }
          var self = this;
          setTimeout(function () {
            self.disableExport = false;
            self.loadingButton = false;
            self.exportToExcel(self.export_data, "Summary", "xlsx");
          }, 1200);

          // if (resData.status == 200) {
          //   console.log(resData)
          //   this.totalData = 10
          // }
          // else if (resData.status == 440){
          //   Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          //     localStorage.clear();
          //     this.$router.push({
          //         path: "/login",
          //   });
          // }
          // else {
          //     Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          // }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          const permission = data.modules.filter(e => e.name==='top_up')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "top_up" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
